<template>
	<div class="policy width1400">
		<div class="text">
			<div class="w-e-text-container">
				<div contenteditable="true" style="width:100%; height:100%; min-height:800px;" class="w-e-text"
					id="text-elem9692142168585649">
					<blockquote>
						<p><b>TERMS OF SERVICE</b><b></b></p>
						<p><b>TERMS AND CONDITIONS</b><b></b></p>
						<p>Please read the Terms and Conditions set out below before ordering or purchasing any
							items from this Site. By ordering or purchasing any items from this Site or thru
							Yeah Freshmart Minimart’s mobile applications, you agree to be bound by these Terms
							and Conditions.</p>
						<p>If you do not agree with the Terms and Conditions of &nbsp;YEAH
							FRESHMART&nbsp;MINIMART, please do not use or access the Site. Your access or use of
							the Site implies your acceptance of the Terms and Conditions set forth herein. YEAH
							FRESHMART&nbsp;MINIMART&nbsp;reserves the right to amend, change, add or modify the
							Terms and Conditions without prior notice to you.</p>
						<p><b>I. About Yeah Fresh</b><b>m</b><b>ar</b><b>t Minimart</b><b></b></p>
						<p>
							<!--[if-->A.&nbsp;
							<!--[endif]-->YEAH FRESHMART MINIMART&nbsp;(hereinafter referred to as "YEAH FRESH")
							is a corporation organized and existing under the laws of the Republic of the
							Philippines, with address at 1208 P. Ocampo St. Brgy.&nbsp;757, San Andres Bukid,
							Manila City, Metro Manila.
						</p>
						<p>
							<!--[if-->B.&nbsp;
							<!--[endif]-->YEAH FRESH is an online delivery service that enables consumers to
							purchase items from&nbsp;us&nbsp;and have these items delivered
							to&nbsp;their&nbsp;doorstep.
						</p>
						<p><b>II. General</b><b></b></p>
						<p>A. YEAH&nbsp;FRESH services shall include receiving through its Site and through its
							mobile application purchases or orders for delivery from&nbsp;our riders or delivery
							app riders&nbsp;will deliver to you.</p>
						<p>B. YEAH FRESH is responsible for the quality of the items offered by&nbsp;us. All
							questions regarding products or items featured on the Site should be directed to us.
						</p>
						<p>C.&nbsp;As part of the registration process on the Site, YEAH FRESH may collect the
							following personally identifiable information about you: Name - including first and
							last name, e-mail address, mobile phone number and other contact details,
							demographic profile (like your age, gender, occupation, education, among others) and
							information about the pages on the Site you visit/access, the links you click on the
							Site, the number of times you access a particular page/feature and any such
							information. Information collected about you is subject to the Privacy Policy of
							YEAH FRESH, which is attached in this Terms and Conditions and is made an integral
							part hereof.</p>
						<p><b>III. Ordering</b><b></b></p>
						<p>A. Any contract for purchase of items from this Site is between you and &nbsp;YEAH
							FRESH. You agree to take particular care when providing us with your details and
							warrant that these details are accurate and complete at the time of ordering. You
							also warrant that the credit or debit card details that you provide are for your own
							credit or debit and that you have sufficient funds to make the payment.</p>
						<p>B. Items and goods purchased from this site are intended for your use and you warrant
							that any items or goods purchased by you are not for resale and that you are acting
							as principal only and not as agent for another party when intended for you warrant
							that any items or goods purchased by you are not for resale and that you are acting
							as principal only and not as agent for another party when availing of YEAH FRESH
							Services.</p>
						<p>C. Please note that some of featured items or goods on the Site may be suitable for
							certain age ranges only. You should check that the product you are ordering is
							suitable for the intended recipient.</p>
						<p>D. When ordering from this Site you may be required to provide a contact number and
							password. You must ensure that you keep the details of your e-mail and password
							secure.</p>
						<p>E.YEAH FRESH will take reasonable care to keep the details of your order and
							facilitate safe payment schemes.YEAH FRESH cannot be held liable for any loss you
							may suffer if a third party procures unauthorized access to any data you provide
							when accessing or ordering from the Site.</p>
						<p>
							<!--[if-->F.&nbsp;
							<!--[endif]-->Any order that you place with us is subject to product availability,
							delivery capacity and acceptance by us.&nbsp;When you place your order
							online,&nbsp;YEAH FRESH will send you an SMS&nbsp;to confirm that YEAH FRESH has
							received it. The automatic confirmation is an acknowledgment of YEAH&nbsp;FRESH
							receipt of your order.&nbsp;You must inform us immediately if any details are
							incorrect. The fact that you receive an automatic confirmation does not necessarily
							mean that YEAH&nbsp;FRESH will be able to fill your order. &nbsp;
						</p>
						<p>G. If the ordered items are available. YEAH FRESH&nbsp;will accept order placement
							and confirm it.&nbsp;Once the details of the order are verified, you will receive a
							text message (SMS), which will serve as order placement confirmation.</p>
						<p>H. The confirmation message will contain an order number with&nbsp;the total price
							and the estimated delivery time of arrival to your designated place.</p>
						<p>I. If the items or goods ordered are not available or if YEAH&nbsp;FRESH is unable to
							render the delivery service, YEAH&nbsp;FRESH will let you know by sending you a text
							message (SMS) or giving you a phone call.</p>
						<p><b>IV. Prices and Availability of Items</b><b></b></p>
						<p>A. The information contained on the Site regarding prices, specifications, and
							availability of the items/products listed on the Site are provided by us. Prices are
							inclusive of the relevant sales tax and other fees.</p>
						<p>B. YEAH&nbsp;FRESH reserves the right to modify the items available for sale on the
							Site or stop listing items.</p>
						<p>C. Prices, specifications and availability of product and/or items may differ and are
							subject to changes, especially when you select delivery or pick-up date that is
							different from the date on which you place your order.</p>
						<p>D. YEAH&nbsp;FRESH observes best efforts to ensure accuracy in posting pricing
							information, without giving full guarantee that discrepancies will not occur. While
							YEAH&nbsp;FRESH exercises diligence to confirm the accuracy of the price information
							contained on the Site, it shall not be liable for any mistake or price
							inconsistency. Should you become aware that the Site contains inaccurate
							information, please let us know by contacting us</p>
						<p><b>V. Payment</b><b></b></p>
						<p>A. Any transaction for the purchase of items or goods from this Site is between you
							and YEAH FRESH.&nbsp;You agree to exercise due care when providing us with your
							details and warrant that these details are accurate and complete at the time of
							ordering.&nbsp;You also warrant that the credit or debit card details that you
							provide are for your own credit and that you have sufficient funds to make the
							payment.</p>
						<p>
							<!--[if-->B.&nbsp;
							<!--[endif]-->The total price for items or goods, including delivery charges and
							other charges will be displayed on the Site when you place your order. Full payment
							must be made for all items or goods dispatched either in cash or thru the use of
							online&nbsp;payment&nbsp;transaction. If you choose Cash on Delivery (COD),
							YEAH&nbsp;FRESH shall pick up and deliver the purchased items to you and collect the
							money for the order upon delivery&nbsp;or online payment.&nbsp;
						</p>
						<p><b>VI. Delivery</b><b></b></p>
						<p>A Estimated delivery time made in the course of ordering items or goods is a mere
							approximation which may still vary depending on the flow of road traffic. Items or
							goods will be delivered to the address designated by you at the time of placing your
							orders.</p>
						<p>B. In case items are not delivered within the estimated delivery time set by us, you
							may contact us by telephone or e-mail. YEAH&nbsp;FRESH engages itself to ensure that
							you receive your order in a timely manner.</p>
						<p>C. If you fail to accept delivery of items or goods despite timely delivery by
							YEAH&nbsp;FRESH, or YEAH&nbsp;FRESH was unable to deliver at the nominated time due
							to your failure to provide appropriate instructions, or authorizations, then such
							items or goods shall be deemed to have been delivered to you and all risk and
							responsibility in relation to such items or goods shall be borne by you. Any
							storage. insurance and other costs, which YEAH&nbsp;FRESH incurs as a result of your
							refusal to accept items or goods without valid and reasonable cause, shall be your
							responsibility and you shall indemnify YEAH&nbsp;FRESH in full for such cost.</p>
						<p>D. YEAH&nbsp;FRESH shall not be liable to you for any losses, liabilities, costs,
							damages, charges or expenses arising out of late delivery when the cause of being
							late is solely attributable to your fault and negligence. This includes but is not
							limited to your failure in giving proper instructions and directions, or failure to
							assign a person to receive the items or goods when you cannot personally receive
							them when delivered.</p>
						<p><b>VII. Return and Refund Policy</b><b></b></p>
						<p>A. In case of a late delivery, the delivery charge will neither be voided nor
							refunded by YEAH&nbsp;FRESH.</p>
						<p>B. Only non-perishable items or goods may be returned to YEAH FRESH, subject to its
							return policies.</p>
						<p>
							<!--[if-->C.&nbsp;
							<!--[endif]-->No returns or refunds are permitted after the items or goods have been
							accepted by you or your representative when delivered.
						</p>
						<p><b>VIII. Cancellation</b><b></b></p>
						<p>
							<!--[if-->A.&nbsp;
							<!--[endif]-->YEAH&nbsp;FRESH may cancel a transaction if the product is not
							available for any reason. You will be notified of this circumstance and any payment
							already made will be refunded to you.
						</p>
						<p>B. YEAH&nbsp;FRESH reserves the right to cancel any order(s) due to the following:
						</p>
						<p>1. When there is reasonable ground to believe that a customer has undertaken a
							fraudulent or suspicious transaction;</p>
						<p>2. When a customer has undertaken a transaction which is not in accordance with the
							provisions of these Terms and Conditions;</p>
						<p>
							<!--[if-->3.&nbsp;
							<!--[endif]-->When YEAH&nbsp;FRESH cannot perform the delivery service for any
							reasons outside its control, which include but are not limited to related logistical
							difficulties, force majeure and other unforeseen events, or
						</p>
						<p>
							<!--[if-->4.&nbsp;
							<!--[endif]-->When customer informed us that he/she wants to cancel his/her order.
						</p>
						<p>
							<!--[if-->B.&nbsp;
							<!--[endif]-->YEAH&nbsp;FRESH reserves the right to deny access to non-compliant
							users or and/or cancel their orders already placed at any time, without&nbsp;notice.
						</p>
						<p><b>IX. Representations or Warranties</b><b></b></p>
						<p><b></b></p>
						<p>
							<!--[if-->A.&nbsp;
							<!--[endif]-->YEAH&nbsp;FRESH does not make any representation or warranties in
							respect of the availability of the items or goods on the Site nor does
							YEAH&nbsp;FRESH expressly or impliedly support or endorse the sale or purchase of
							any items or goods on the Site. YEAH&nbsp;FRESH accepts no liability for any errors
							or omissions, whether on behalf of itself or third parties.
						</p>
						<p>B. YEAH&nbsp;FRESH exercises best effort display available items or goods, including
							accurate appearance in terms of color, size, shape and description. However,
							YEAH&nbsp;FRESH shall not be liable for any inconsistency with appearance or
							inaccuracy with claim of color, which involves trademark ownership.</p>
						<p>C. You acknowledge and agree that YEAH&nbsp;FRESH shall act as your agent for
							retrieval and delivery of items or goods purchased on the Site, and that
							YEAH&nbsp;FRESH has no relationship with you other than to provide such retrieval
							and delivery service. At no time shall the YEAH&nbsp;FRESH have any right, title or
							interest to such items or goods.&nbsp;YEAH&nbsp;FRESH controls&nbsp;over the
							quality, failure to provide or any other aspect whatsoever of the items or goods and
							is not responsible for damages or delays as a result of products which are out of
							stock, unavailable or back ordered.</p>
						<p><b></b></p>
						<p><b>X. Information and Privacy Policy</b><b></b></p>
						<p><b></b></p>
						<p>
							<!--[if-->A.&nbsp;
							<!--[endif]-->You authorize YEAH&nbsp;FRESH to use, store or otherwise process your
							personal information in order to provide the delivery service to you and for
							marketing and credit control purposes, which may entail the disclosure of your
							personal information to selected third parties from time to time where
							YEAH&nbsp;FRESH believes that the services offered by such third parties may be of
							interest to you or where this is required by law or in order to provide the delivery
							service. A detailed account of this information can be found in YEAH&nbsp;FRESH's
							Privacy Policy.
						</p>
						<p>B. You are entitled to request a copy of the personal information YEAH&nbsp;FRESH
							holds on you. Please contact us if you wish to request this information.</p>
						<p><b>XI. Limitation of Liability</b><b></b></p>
						<p>A. YEAH&nbsp;FRESH exercises diligence in keeping all information on the Site
							accurate. YEAH&nbsp;FRESH cannot warrant that use of the Site will be error free or
							fit for all purposes, or the Site or the server that makes it available are free of
							viruses or bugs but ensures maintenance of the Site for its continuous
							functionality.</p>
						<p>B. By accepting these terms of use you agree to relieve us from any liability
							whatsoever arising from your use of information from any third party, or your use of
							any third party Site, or your purchase of any items or goods from YEAH FRESH.</p>
						<p>C. YEAH&nbsp;FRESH disclaims any and all liability to you for the delivery of items
							or goods to the fullest extent permissible under applicable law. This does not
							affect your statutory rights as a consumer. If YEAH&nbsp;FRESH is found liable for
							any loss or damage to you such liability is limited to the amount you have paid for
							the relevant items or goods. YEAH&nbsp;FRESH cannot accept any liability for any
							loss, damage or expense, including any direct or indirect loss such as loss of
							profits to you, howsoever arising</p>
						<p>
							<!--[if-->D.&nbsp;
							<!--[endif]-->YEAH&nbsp;FRESH does not accept any liability for any delays,
							failures, errors or omissions or loss of transmitted information, viruses or other
							contamination or destructive properties transmitted to you or your computer
							system&nbsp;due to usage of the Site.
						</p>
						<p>E. YEAH&nbsp;FRESH shall not be held liable for any failure or delay in performing
							services or delivering goods where such failure arises as a result of any act or
							omission, which is outside our reasonable control such as all overwhelming and
							unforeseen events caused directly and exclusively by force majeure that can be
							neither anticipated, nor controlled, nor prevented by the exercise of prudence,
							diligence, and care, including but not limited to: war, riot, civil commotion;
							compliance with any law or governmental order, rule, regulation or direction and
							acts of third parties.</p>
						<p>F. If YEAH&nbsp;FRESH has agreed to provide identical or similar orders to more than
							one customers and is prevented from fully meeting its obligations to you by reason
							of an event of force majeure, YEAH&nbsp;FRESH may decide at its sole discretion
							which orders YEAH&nbsp;FRESH will fill and determine its extent.</p>
						<p>G. The items or goods sold by YEAH&nbsp;FRESH are provided for local domestic and
							consumer use only. Accordingly, YEAH&nbsp;FRESH does not accept liability for any
							indirect loss, consequential loss, loss of data, loss of income or profit, loss of
							damage to property and/or loss from claims of third parties arising out of the use
							of the Site or for any items or goods delivered by YEAH&nbsp;FRESH.</p>
						<p>
							<!--[if-->G.&nbsp;
							<!--[endif]-->YEAH&nbsp;FRESH observes diligence to prevent Internet fraud and
							secures any data collected from you. YEAH&nbsp;FRESH cannot be held liable in the
							event of a breach in security computer servers or those of third parties.
						</p>
						<p>I. YEAH&nbsp;FRESH reserves the right to block or refuse future service/s to any
							customers or online shopper/s when there is reasonable belief that an abuse of
							Vouchers and/or discount codes is committed or when the transaction involves fraud
							or attempt to commit fraud. Additionally, should an abuse of vouchers or discount
							codes be committed, YEAH&nbsp;FRESH reserves the right to seek compensation from any
							and all violators,</p>
						<p>J. Offers are subject to YEAH&nbsp;FRESH's full discretion and may be withdrawn at
							any time without notice.</p>
						<p><b>XII. Miscellaneous Provisions</b><b></b></p>
						<p>A. These Terms and Conditions and this User Agreement shall be governed by and
							construed in accordance with the laws of the Republic of the Philippines. The
							parties hereto submit to the exclusive jurisdiction of the courts of Makati,
							Philippines.</p>
						<p>B. This User Agreement is effective unless and until terminated by either you or
							YEAH&nbsp;FRESH.&nbsp;You may terminate this User Agreement at any time, provided
							that you discontinue any further use of the Site. YEAH&nbsp;FRESH may terminate this
							User Agreement at any time and may do so immediately without notice, and accordingly
							deny your access to the Site.</p>
						<p>
							<!--[if-->B.&nbsp;
							<!--[endif]-->Such termination will be without any liability to YEAH&nbsp;FRESH.
							YEAH&nbsp;FRESH's right to be indemnified pursuant to the terms hereof, shall
							survive any termination of this User Agreement. Any such termination of the User
							Agreement shall not cancel your obligation to pay for items or goods already ordered
							from the Site or affect any liability that may have arisen under the User Agreement
							prior to the date of termination.
						</p>
						<p>D. YEAH&nbsp;FRESH may subcontract any part or parts of the Services provides to you
							at any time YEAH&nbsp;FRESH may assign or novate any part o parts of its rights
							under these Terms and Conditions without your consent.</p>
						<p>E. YEAH&nbsp;FRESH may alter or vary the Terms and Conditions at any time without
							giving notice to you.</p>
						<p>F. Payment must be made either at the time of ordering the items or goods from us by
							credit card or at the time of delivery by cash. Failure to pay on time will result
							in the cancellation of your order.</p>
						<p>G. Initiating any automated system or program in&nbsp;connection with our Site or its
							online ordering&nbsp;functionality is not allowed.</p>
						<p>H. Collecting any personally identifiable information from the Site, including the
							use of communication systems provided by the Site for any commercial solicitation
							purposes are not allowed. Distribution or publication of vouchers or codes,
							solicitation for any reason&nbsp;whatsoever to any users of the Site, and hacking or
							scraping the Site, are prohibited acts which will be dealt accordingly.</p>
						<p>I. The Terms and Conditions and User&nbsp;Agreement together with the Privacy
							Policy&nbsp;any order form and payment instructions constitute the entire agreement
							between you and YEAH&nbsp;FRESH. In the event of any conflict between these Terms
							and Conditions and any other term or provision on the Site, these Terms and
							Conditions shall prevail.</p>
						<p>
							<!--[if-->J.&nbsp;
							<!--[endif]-->If any of the Term and Conditions shall be deemed invalid, illegal or
							unenforceable, such term or condition shall be deemed not written and the remainder
							shall continue in force without such term or condition.
						</p>
						<p>K. No delay or failure in our part to enforce our rights or remedies under these
							Terms and Conditions and User Agreement shall constitute a waiver on our part of
							such rights or remedies unless such waiver is confirmed&nbsp;in writing.</p>
						<p><b>XIII. Contact Us</b><b></b></p>
						<p>
							<!--[if-->A.&nbsp;
							<!--[endif]-->For further comments, suggestions and feedback, you may send it to
							YEAH&nbsp;FRESH e-mail at: <a
								href="mailto:yeahfreshmartminimart@gamil.com"><u>yeahfreshmartminimart</u><u>8888</u><u>@gm</u><u>a</u><u>il.com</u></a>
						</p>
						<p>B. YEAH&nbsp;FRESH takes complaints very seriously and aims to respond to your
							complaints within 5 business days. Any complaints or concerns pertaining to the
							Site, including those pertaining to breach of the Terms of Use or and other polices
							should be addressed to our e-mail address: <a
								href="mailto:yeahfreshmartminimart@gamil.com"><u>yeahfreshmartminimart</u><u>8888</u><u>@gm</u><u>a</u><u>il.com</u></a>
						</p>
						<p>YEAH&nbsp;FRESHMART</p>
						<p>Delivering goods from your favorite local stores. We currently serve
							Makati,&nbsp;Taguig, Pasay, Cavite, Laguna, Antipolo, Cainta, Caloocan, Dasmarinas
							Cavite, General Trias Cavite, Las Pinas, Malabon, Malolos Bulacan, Mandaluyong,
							Manila, Marikina,, Montalban, Muntinlupa, Paranaque, Pasig, Pateros, Quezon City,
							San Juan, San Pedro Laguna, Silang Cavite, Sta. Rosa Laguna,&nbsp;and Valenzuela and
							are working on expanding to more areas.​</p>
					</blockquote>
				</div>
				<div class="placeholder" style="display:none;">请输入正文</div>
				<div class="w-e-img-drag-mask" style="display:none;">
					<div class="w-e-img-drag-show-size"></div>
					<div class="w-e-img-drag-rb"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "policy",
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		destroyed(){
			this.$store.commit('user/setHeadShow',true)
			this.$store.commit("user/setGoLogin", false)
		},
		created(){
			this.$store.commit('user/setHeadShow',false)
			this.$store.commit("user/setGoLogin", false)
		},
		mounted(){
			this.$store.commit('user/setHeadShow',false)
			this.$store.commit("user/setGoLogin", false)
		}
	};
</script>

<style scoped="scoped">
	.text {
		padding: 30px;
		box-sizing: border-box;
		overflow: hidden;
		white-space: normal;
		word-break: break-all;
	}
	p {
		margin-bottom: 20px;
	}
</style>
